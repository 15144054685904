<template>
    <v-dialog
        persistent
        v-model="editDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="300"
        min-width="300"
        max-width="300"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.addMonthlyExchangeRates') }}</v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="exchangeRateForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs6 lg5 md5 pl-5 py-1 pr-3 class="align-center">{{ $t('message.rateDateFirstDay') }}</v-flex>
                        <v-flex xs6 lg7 md7 py-1>
                            <v-menu
                                    ref="dateMenu"
                                    v-model="dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    max-width="290px"
                                    min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                            :value="computedDateFormatted"
                                            append-icon="event"
                                            autocomplete="new-password"
                                            clearable
                                            class="custom force-text-left"
                                            hint="DD/MM/YY format"
                                            hide-details="auto"
                                            background-color="white"
                                            persistent-hint
                                            readonly
                                            solo
                                            @click:clear="Currencyrate__dated = null"
                                            v-on="on"
                                    />
                                </template>
                                <v-date-picker
                                        first-day-of-week="1"
                                        locale-first-day-of-year="4"
                                        show-week
                                        @input="dateMenu = false"
                                        @change="checkExistingRates"
                                        v-model="Currencyrate__date"
                                />
                            </v-menu>
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="existingRatesFound == true  ">
                        <v-flex lg12 py-1>
                            <v-alert dense outlined type="error" class="ma-0">{{ $t('message.existingRatesFound') }}</v-alert>
                        </v-flex>
                    </v-layout>
                    <template v-if="existingRatesFound == false">
                        <v-layout row>
                            <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center"></v-flex>
                            <v-flex xs6 lg7 md7 py-1>
                                <v-btn :loading="loading.get" @click="getBankNegaraRates">{{ $t('message.getNegaraRates') }}</v-btn>
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">1 MYR =</v-flex>
                            <v-flex xs6 lg7 md7 py-1>
                                <v-text-field
                                        :rules="[...validationRules.required, ...validationRules.amount]"
                                        :value="Currencyrate__myr"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        readonly
                                        solo
                                        @change="Currencyrate__myr = $event"
                                />
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">1 USD =</v-flex>
                            <v-flex xs6 lg7 md7 py-1>
                                <v-text-field
                                        :loading="loading.get"
                                        :rules="[...validationRules.required, ...validationRules.amount]"
                                        :value="Currencyrate__usd"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Currencyrate__usd = $event"
                                />
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">1 EUR =</v-flex>
                            <v-flex xs6 lg7 md7 py-1>
                                <v-text-field
                                        :loading="loading.get"
                                        :rules="[...validationRules.required, ...validationRules.amount]"
                                        :value="Currencyrate__eur"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Currencyrate__eur = $event"
                                />
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">1 AUD =</v-flex>
                            <v-flex xs6 lg7 md7 py-1>
                                <v-text-field
                                        :loading="loading.get"
                                        :rules="[...validationRules.required, ...validationRules.amount]"
                                        :value="Currencyrate__aud"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Currencyrate__aud = $event"
                                />
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">1 CAD =</v-flex>
                            <v-flex xs6 lg7 md7 py-1>
                                <v-text-field
                                        :loading="loading.get"
                                        :rules="[...validationRules.required, ...validationRules.amount]"
                                        :value="Currencyrate__cad"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Currencyrate__cad = $event"
                                />
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">1 SGD =</v-flex>
                            <v-flex xs6 lg7 md7 py-1>
                                <v-text-field
                                        :loading="loading.get"
                                        :rules="[...validationRules.required, ...validationRules.amount]"
                                        :value="Currencyrate__sgd"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Currencyrate__sgd = $event"
                                />
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">1 THB =</v-flex>
                            <v-flex xs6 lg7 md7 py-1>
                                <v-text-field
                                        :loading="loading.get"
                                        :rules="[...validationRules.required, ...validationRules.amount]"
                                        :value="Currencyrate__thb"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Currencyrate__thb = $event"
                                />
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">1 IDR =</v-flex>
                            <v-flex xs6 lg7 md7 py-1>
                                <v-text-field
                                        :loading="loading.get"
                                        :rules="[...validationRules.required, ...validationRules.amount]"
                                        :value="Currencyrate__idr"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Currencyrate__idr = $event"
                                />
                            </v-flex>
                        </v-layout>
                        <v-layout row>
                            <v-flex xs6 lg5 md5 pl-5 py-1 class="align-center">1 VND =</v-flex>
                            <v-flex xs6 lg7 md7 py-1>
                                <v-text-field
                                        :loading="loading.get"
                                        :rules="[...validationRules.required, ...validationRules.amount]"
                                        :value="Currencyrate__vnd"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="Currencyrate__vnd = $event"
                                />
                            </v-flex>
                        </v-layout>
                    </template>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-3">
                <v-btn color="default" small @click="editDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.add"
                        color="info"
                        small
                        @click="addMonthlyExchangeRates()"
                >{{ $t('message.add') }}</v-btn>
                <v-spacer/>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {formatDate} from "Helpers/helpers";
import {api} from "Api";
import {mapGetters} from "vuex";

export default {
    name: "AddMonthlyExchangeRates",
    props: ['dialog','dialog-closed','create-done'],
    data() {
        return {
            Currencyrate__date: null,
            Currencyrate__usd: null,
            Currencyrate__eur: null,
            Currencyrate__aud: null,
            Currencyrate__cad: null,
            Currencyrate__sgd: null,
            Currencyrate__myr: 1,
            Currencyrate__thb: null,
            Currencyrate__idr: null,
            Currencyrate__vnd: null,
            dateMenu: false,
            existingRatesFound: false,
            loading: {
                add: false,
                get: false
            },
            edit_dialog: false,
            // rules: {
            //     date: v => !!v || this.$t('message.required'),
            //     currency: v => !!v || this.$t('message.required'),
            //     rate: v => !!v || this.$t('message.required')
            // },
            validForm: true
        }
    },
    computed: {
        ...mapGetters([
            'validationRules'
        ]),
        computedDateFormatted(){
            return formatDate(this.Currencyrate__date)
        },
        editDialog: {
            get() {
                return this.edit_dialog;
            },
            set(value){
                this.edit_dialog = value
                if(value == false) {
                    this.Currencyrate__date = null
                    this.existingRatesFound = false
                    this.Currencyrate__usd = null
                    this.Currencyrate__eur = null
                    this.Currencyrate__aud = null
                    this.Currencyrate__cad = null
                    this.Currencyrate__sgd = null
                    this.Currencyrate__myr = 1
                    this.Currencyrate__thb = null
                    this.Currencyrate__idr = null
                    this.Currencyrate__vnd = null
                    this.$emit('dialog-closed')
                }
            }
        }
    },
    methods: {
        addMonthlyExchangeRates() {
            if(this.$refs.exchangeRateForm.validate()){
                let currencyDate = this.Currencyrate__date
                let currencyRates = {
                    USD: this.Currencyrate__usd,
                    EUR: this.Currencyrate__eur,
                    AUD: this.Currencyrate__aud,
                    CAD: this.Currencyrate__cad,
                    SGD: this.Currencyrate__sgd,
                    MYR: this.Currencyrate__myr,
                    THB: this.Currencyrate__thb,
                    IDR: this.Currencyrate__idr,
                    VND: this.Currencyrate__vnd,
                }
                this.loading.add = true
                api
                    .post('/currencyrates/monthly',{
                        currencyDate: currencyDate,
                        currencyRates: currencyRates
                    })
                    .then((response) => {
                        if(response.data.status == 'success'){
                            this.$toast.success(this.$t('message.successes.exchangeRateAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.add = false
                            this.$emit('create-done')
                            this.editDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.exchangeRateNotAdded'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.add = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.exchangeRateNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    })
            } else {
                this.$toast.error(this.$t('message.errors.exchangeRateNotAdded'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        checkExistingRates() {
            let currencyDate = this.Currencyrate__date
            api
                .get( '/currencyrates/check-existing/' + currencyDate )
                .then(response => {
                    if(response.data.status == 'success'){
                        this.existingRatesFound = true
                    } else if(response.data.status == 'error'){
                        this.existingRatesFound = false
                    }
                })
                .catch(() => {
                    this.$toast.error(this.$t('message.errors.cannotCheckForExistingRates'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
        },
        getBankNegaraRates() {
            let currencyDate = this.Currencyrate__date
            if(currencyDate == null){
                this.$toast.error(this.$t('message.errors.dateNotProvided'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            } else {
                this.loading.get = true
                api
                    .get('/currencyrates/bank-negara/' + currencyDate)
                    .then(response => {
                        if (response.data.status == 'success') {
                            let rates = response.data.rates
                            this.Currencyrate__usd = rates.USD
                            this.Currencyrate__eur = rates.EUR
                            this.Currencyrate__aud = rates.AUD
                            this.Currencyrate__cad = rates.CAD
                            this.Currencyrate__sgd = rates.SGD
                            this.Currencyrate__thb = rates.THB
                            this.Currencyrate__idr = rates.IDR
                            this.Currencyrate__vnd = rates.VND
                            this.loading.get = false
                        } else if (response.data.status == 'error') {
                            this.$toast.error(response.data.message,
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.get = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.negaraRatesNotPulled'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.get = false
                    })
            }
        }
    },
    watch: {
        dialog (value) {
            this.edit_dialog = value
        }
    },
    created() {}
}
</script>

<style scoped>

</style>